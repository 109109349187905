.App {
    margin: 5px 10px;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: grey;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(232, 132, 232);

    >* {
        margin: 0.25rem;
    }

    .btn {
        font-size: 2rem;
        font-weight: 700;
        border: none;
        border-radius: 20px;
        color: rgb(237, 62, 237);
        background-color: transparent;
        cursor: pointer;
        padding: 0.3rem;
        background-color: rgba(237, 62, 237,0.15);
    }

    .btn:hover {
        color: antiquewhite;
        background-color: rgb(232, 132, 232);
    }


}

#secondary-controls {
    display: flex;
    justify-content: space-around;
    align-items: space-around;
    gap: 0.65rem;
    margin: 0.5rem;

    #break-controls>*:not(h3),
    #session-controls>*:not(h3) {
        font-size: 2rem;
    }

}

#time-left{
    font-size: 7.5rem;
    font-weight: 900;
}

#timer-label {
    font-size: 3.75rem;
    font-weight: 700;
}